import React, { Component } from 'react';
import { connect } from "react-redux";
import {
    withRouter
} from "react-router-dom";

import { addBooth } from "../redux/actions/booths";
import { getBrandAll } from "../redux/actions/brands";
import { getFrameAll, getFrameSearch } from "../redux/actions/frames";

import {
    Box,
    Paper,
    Switch,
    Select,
    Button,
    Container,
    Typography,
    InputLabel,
    MenuItem,
    FormControl,
    FormControlLabel,
    FormGroup,
    TextField
} from '@mui/material';

import Checkbox from '@mui/material/Checkbox';
import Autocomplete from '@mui/material/Autocomplete';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

class BoothAdd extends Component {
    constructor(props) {
        super(props);
        this.state = {
            brands:null,
            frames:null,
            selectedFrame:[],
            data:{
                idbrand:1,
                price:35000,
                name:"",
                boothkey:"",
                description:"",
                is_active:true
            },
            err:null
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleChange(e){
        let tmpVal = e.target.value;
        let ctx = this;
        if(e.target.name === "idbrand"){
            this.setState({selectedFrame:[]});
            ctx.props.getFrameSearch({idbrand:tmpVal}).then((ef)=>{
                if(ef.code === 200){
                    var dataframe = ef.data;
                    var selectedIDtmp = [];
                    ctx.state.selectedFrame.map((item)=>(
                        selectedIDtmp.push(dataframe.findIndex(x => x.id === item.id))
                    ))
                    ctx.setState({ frames:dataframe, selectedID:selectedIDtmp });
                }else{
                    ctx.setState({ frames:null });
                }
            }).catch((err)=>{
                console.log(err);
            });
        }

        if(e.target.name === "is_active"){
            tmpVal = JSON.parse(e.target.checked);
        }        
        this.setState({
            data: {
                ...this.state.data,
                [e.target.name]: tmpVal
            }
        });
    }

    handleSubmit(e){
        e.preventDefault();
        const answer = window.confirm("Are you sure?");
        if (answer) {
            var ctx = this;
            this.props.addBooth({
                boothkey:ctx.state.data.boothkey,
                name:ctx.state.data.name,
                price:ctx.state.data.price,
                description:ctx.state.data.description,
                is_active:ctx.state.data.is_active,
                idbrand:ctx.state.data.idbrand,
                selectedFrame:ctx.state.selectedFrame
            }).then((e)=>{
                ctx.props.history.replace("/dashboard/booth");
            }).catch((err)=>{
                console.log(err);
                alert(err.message);
            });
        }else{
            console.log("dismiss!");
        }
    }

    componentDidMount(){
        var ctx = this;
        this.props.getBrandAll().then((e)=>{
            if(e.code === 200){
                ctx.setState({brands:e.data});
            }else{
                console.log("Something Error");
            }
        }).catch((err)=>{
            console.log(err);
        });

        this.props.getFrameSearch({idbrand:this.state.data.idbrand}).then((ef)=>{
            if(ef.code === 200){
                var dataframe = ef.data;
                ctx.setState({ frames:dataframe });
            }else{
                alert("Something Error");
            }
        }).catch((err)=>{
            console.log(err);
        });
    }

    render() {        
        return (
            <Container className='main-panel booth'>                
                <Box mt={3}>
                    <Paper component="form" className='panel-container-box' onSubmit={this.handleSubmit}>
                    <Typography variant="h5" mt={1} mb={2}>Create New Booth</Typography>
                    <FormGroup>
                        {this.state.brands!==null?
                        <FormControl sx={{ minWidth: 120 }}>
                            <InputLabel id="select-brand-label">Brand</InputLabel>
                            <Select
                                size="small"
                                labelId="select-brand-label"
                                id="select-brand"
                                name="idbrand"
                                value={this.state.data.idbrand}
                                label="Brand"
                                onChange={this.handleChange}
                                sx={{marginBottom:"20px"}}
                            >
                                {this.state.brands.map((item)=>{
                                    return <MenuItem value={item.id} key={item.id}>{item.name}</MenuItem>
                                })}
                            </Select>
                        </FormControl>
                        :<Typography>Loading...</Typography>}
                        <TextField
                            size="small"
                            id="input-boothkey"
                            name="boothkey"
                            label="Booth Key"
                            helperText="Tanpa spasi, huruf kecil"
                            value={this.state.data.boothkey}
                            onChange={this.handleChange}
                            required
                            sx={{marginBottom:"20px"}}
                        />
                        
                        <TextField
                            size="small"
                            id="input-boothname"
                            name="name"
                            label="Booth Name"
                            helperText="Nama Booth"
                            value={this.state.data.name}
                            onChange={this.handleChange}
                            required
                            sx={{marginBottom:"20px"}}
                        />

                        <TextField
                            size="small"
                            id="input-boothprice"
                            name="price"
                            label="Booth Price"
                            helperText="Harga Booth"
                            value={this.state.data.price}
                            onChange={this.handleChange}
                            required
                            sx={{marginBottom:"20px"}}
                        />

                        <TextField
                            size="small"
                            id="input-boothdescription"
                            name="description"
                            label="Booth Description"
                            helperText="Deskripsi Booth"
                            value={this.state.data.description!==null?this.state.data.description:""}
                            onChange={this.handleChange}
                            multiline
                            rows={3}
                            sx={{marginBottom:"20px"}}
                        />

                        {this.state.frames!==null?
                        <Autocomplete
                            multiple
                            size="small"
                            id="checkboxes-tags-demo"
                            onChange={(event, newValue) => {
                                this.setState({selectedFrame:newValue});                                
                            }}
                            value={this.state.selectedFrame || []}
                            // defaultValue={this.state.selectedID.map((item)=>(this.state.frames[item]))}
                            options={this.state.frames}                            
                            disableCloseOnSelect
                            // filterSelectedOptions
                            autoHighlight
                            sx={{marginBottom:"20px"}}
                            getOptionLabel={(option) => option.name}                            
                            renderOption={(props, option, { selected }) => {
                                return(
                                <li {...props} style={{padding:"0px"}} data-idframe={option.id}>
                                <Checkbox
                                    icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                                    checkedIcon={<CheckBoxIcon fontSize="small" />}
                                    style={{ marginRight: 8 }}
                                    checked={ selected }
                                />
                                <img alt="fullimage-thumbnail" width="50px" height="auto" src={option.fullurl_image} style={{marginRight:"10px"}}/>
                                {option.name}
                                </li>
                                )
                            }}
                            renderInput={(params) => (
                                <TextField {...params} label="Frames" placeholder="Favorites" helperText="Pilih frame"/>
                            )}
                        />
                        :"Loading"}
                        
                        <FormControlLabel 
                            control={<Switch defaultChecked />} 
                            label="Active"
                            name="is_active"
                            value={this.state.data?.is_active}
                            onChange={this.handleChange}
                            sx={{marginBottom:"20px", marginLeft:"5px"}}
                        />

                        <Button type="submit" variant="contained">Create</Button>
                    </FormGroup>
                    </Paper>
                </Box>
            </Container>            
        )
    }
}

function mapDispatchToProps(dispatch) {
    return {
        addBooth: data => dispatch(addBooth(data)),
        getBrandAll: data => dispatch(getBrandAll(data)),
        getFrameAll: data => dispatch(getFrameAll(data)),
        getFrameSearch: data => dispatch(getFrameSearch(data))
    };
}

const mapStateToProps = state => ({
    ...state
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(BoothAdd));