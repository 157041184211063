import axios from 'axios';
import {BASE_URL, BASE_VERSION} from "../config";

export const syncBooth = (payload) => {
  return (dispatch) => {
    return( axios.post(BASE_URL+BASE_VERSION+'booth/sync', {
        boothkey:payload.boothkey,
      },{ 
        headers: { 
          Authorization: "Bearer "+window.localStorage.getItem('app_pt') 
        }
      }).then(function (response) {
        console.log(response);
        payload = response.data;
        return payload;
      }).catch(function (error) {
        console.log(error);
        payload = error;
        return payload;
      })
    )
  }
}

export const addBooth = (payload) => {
  return (dispatch) => {
    return( axios.post(BASE_URL+BASE_VERSION+'booth', {
        boothkey:payload.boothkey,
        name:payload.name,
        price:payload.price,
        description:payload.description,
        is_active:payload.is_active,
        idbrand:payload.idbrand,
        frames:payload.selectedFrame
      },{ 
        headers: { 
          Authorization: "Bearer "+window.localStorage.getItem('app_pt') 
        }
      }).then(function (response) {
        console.log(response);
        payload = response.data;        
        return payload;
      }).catch(function (error) {
        console.log(error);
        payload = error;
        return payload;
      })
    )
  }
}

export const updateBooth = (payload) => {
  return (dispatch) => {
    return( axios.put(BASE_URL+BASE_VERSION+'booth/'+payload.id, {
        boothkey:payload.boothkey,
        name:payload.name,
        price:payload.price,
        description:payload.description,
        is_active:payload.is_active,
        idbrand:payload.idbrand,
        frames:payload.selectedFrame
      },{ 
        headers: { 
          Authorization: "Bearer "+window.localStorage.getItem('app_pt') 
        }
      }).then(function (response) {
        console.log(response);
        payload = response.data;        
        return payload;
      }).catch(function (error) {
        console.log(error);
        payload = error;
        return payload;
      })
    )
  }
}

export const getBooth = (payload) => {
  return (dispatch) => {
    return( axios.get(BASE_URL+BASE_VERSION+'booth/'+payload.boothkey, { 
        headers: { 
          Authorization: "Bearer "+window.localStorage.getItem('app_pt') 
        }, validateStatus: () => true
      }).then(function (response) {
        let payload = response.data;
        return payload;
      }).catch(function (error) {
        let payload = error;
        return payload;
      })
    )
  }
}

export const getBoothAll = (payload) => {
  return (dispatch) => {
    return( axios.get(BASE_URL+BASE_VERSION+'booth/all?is_active=1', { 
        params:payload,
        headers: { 
          Authorization: "Bearer "+window.localStorage.getItem('app_pt') 
        }, validateStatus: () => true
      }).then(function (response) {
        let payload = response.data;
        return payload;
      }).catch(function (error) {
        let payload = error;
        return payload;
      })
    )
  }
}

export const getBoothSearch = (payload) => {
  return (dispatch) => {
    return( axios.get(BASE_URL+BASE_VERSION+'booth/search', {
        params: payload,
        headers: { 
          Authorization: "Bearer "+window.localStorage.getItem('app_pt') 
        }, validateStatus: () => true
      }).then(function (response) {
        let payload = response.data;
        return payload;
      }).catch(function (error) {
        let payload = error;
        return payload;
      })
    )
  }
}

export const deleteBooth = (payload) => {
  return (dispatch) => {
    return( axios.delete(BASE_URL+BASE_VERSION+'booth/'+payload.id, { 
        headers: { 
          Authorization: "Bearer "+window.localStorage.getItem('app_pt') 
        }, validateStatus: () => true
      }).then(function (response) {
        let payload = response.data;
        return payload;
      }).catch(function (error) {
        let payload = error;
        return payload;
      })
    )
  }
}