import React, { Component } from 'react';
import { connect } from "react-redux";
import {
    // Link,
    withRouter
} from "react-router-dom";

import { addBrand } from "../redux/actions/brands";

import {
    Box,
    // Grid,
    Paper,
    Switch,
    // Select,
    Button,
    Container,
    Typography,
    // InputLabel,
    // MenuItem,
    // FormControl,
    // FormHelperText,
    FormControlLabel,
    FormGroup,
    TextField
} from '@mui/material';

class BrandAdd extends Component {
    constructor(props) {
        super(props);
        this.state = {
            brands:null,
            data:{
                name:"",
                description:"",
                is_active:true
            },
            err:null
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleChange(e){
        let tmpVal = e.target.value;
        if(e.target.name === "is_active"){
            tmpVal = JSON.parse(e.target.checked);
        }        
        this.setState({
            data: {
                ...this.state.data,
                [e.target.name]: tmpVal
            }
        });
    }

    handleSubmit(e){
        e.preventDefault();
        const answer = window.confirm("Are you sure?");
        if (answer) {
            var ctx = this;
            this.props.addBrand({
                name:ctx.state.data.name,
                description:ctx.state.data.description,
                is_active:ctx.state.data.is_active
            }).then((e)=>{
                ctx.props.history.replace("/dashboard/brand");
            }).catch((err)=>{
                console.log(err);
                alert(err.message);
            });
        }else{
            console.log("dismiss!");
        }
    }

    render() {        
        return (
            <Container className='main-panel brand'>                
                <Box mt={3}>
                    <Paper component="form" className='panel-container-box' onSubmit={this.handleSubmit}>
                    <Typography variant="h5" mt={1} mb={2}>Create New Brand</Typography>
                    <FormGroup>                        
                        <TextField
                            size="small"
                            id="input-brandname"
                            name="name"
                            label="Brand Name"
                            helperText="Nama Brand"
                            value={this.state.data.name}
                            onChange={this.handleChange}
                            required
                            sx={{marginBottom:"20px"}}
                        />

                        <TextField
                            size="small"
                            id="input-branddescription"
                            name="description"
                            label="Brand Description"
                            helperText="Deskripsi Brand"
                            value={this.state.data.description!==null?this.state.data.description:""}
                            onChange={this.handleChange}
                            multiline
                            rows={3}
                            sx={{marginBottom:"20px"}}
                        />
                        
                        <FormControlLabel 
                            control={<Switch defaultChecked />} 
                            label="Active"
                            name="is_active"
                            value={this.state.data?.is_active}
                            onChange={this.handleChange}
                            sx={{marginBottom:"20px", marginLeft:"5px"}}
                        />

                        <Button type="submit" variant="contained">Create</Button>
                    </FormGroup>
                    </Paper>
                </Box>
            </Container>            
        )
    }
}

function mapDispatchToProps(dispatch) {
    return {
        addBrand: data => dispatch(addBrand(data))
    };
}

const mapStateToProps = state => ({
    ...state
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(BrandAdd));