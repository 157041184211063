import axios from 'axios';
import {BASE_URL, BASE_VERSION} from "../config";

export const addFrame = (payload) => {
  return (dispatch) => {

    var data = new FormData();
    data.append("slug", payload.slug);
    data.append("name", payload.name);
    data.append("description", payload.description);
    data.append("layout", payload.layout);
    data.append("frame_image64", payload.frame_image);
    data.append("thumbnail_image64", payload.thumbnail_image);
    data.append("idbrand", payload.idbrand);
    data.append("is_active", payload.is_active);
    data.append("booths", JSON.stringify(payload.selectedBooth));
    
    // onUploadProgress: (progressEvent) => {
    //   const { loaded, total } = progressEvent;
    //   payload.is_upload = true;          
    //   payload.loaded = Math.floor((loaded / total) * 100);
    //   payload.total = total;
    //   dispatch({ type: 'UPLOAD_PROGRESS', payload })
    // },
    // headers: { Authorization: tkk },
    // 'Content-Type': 'multipart/form-data',

    return( axios.post(BASE_URL+BASE_VERSION+'frame', data, { 
        onUploadProgress: (progressEvent) => {
          const { loaded, total } = progressEvent;
          payload.progress(Math.floor((loaded / total) * 100));
          // payload.is_upload = true;
          // payload.loaded = Math.floor((loaded / total) * 100);
          // payload.total = total;
          // dispatch({ type: 'UPLOAD_PROGRESS', payload })
        },
        headers: { 
          Authorization: "Bearer "+window.localStorage.getItem('app_pt') 
        },
        // 'Content-Type': 'multipart/form-data',
      }).then(function (response) {
        console.log(response);
        payload = response.data;       
        return payload;
      }).catch(function (error) {
        console.log(error);
        payload = error;
        return payload;
      })
    )
  }
}

export const updateFrame = (payload) => {
  return (dispatch) => {

    var data = new FormData();
    data.append("slug", payload.slug);
    data.append("name", payload.name);
    data.append("description", payload.description);
    data.append("layout", payload.layout);
    data.append("frame_image64", payload.frame_image);
    data.append("thumbnail_image64", payload.thumbnail_image);
    data.append("idbrand", payload.idbrand);
    data.append("is_active", payload.is_active);
    data.append("booths", JSON.stringify(payload.selectedBooth));
    
    // onUploadProgress: (progressEvent) => {
    //   const { loaded, total } = progressEvent;
    //   payload.is_upload = true;          
    //   payload.loaded = Math.floor((loaded / total) * 100);
    //   payload.total = total;
    //   dispatch({ type: 'UPLOAD_PROGRESS', payload })
    // },
    // headers: { Authorization: tkk },
    // 'Content-Type': 'multipart/form-data',

    return( axios.put(BASE_URL+BASE_VERSION+'frame/'+payload.id, data, { 
        onUploadProgress: (progressEvent) => {
          const { loaded, total } = progressEvent;
          payload.progress(Math.floor((loaded / total) * 100));
          // payload.is_upload = true;
          // payload.loaded = Math.floor((loaded / total) * 100);
          // payload.total = total;
          // dispatch({ type: 'UPLOAD_PROGRESS', payload })
        },
        headers: { 
          Authorization: "Bearer "+window.localStorage.getItem('app_pt') 
        },
        'Content-Type': 'multipart/form-data',
      }).then(function (response) {
        console.log(response);
        payload = response.data;       
        return payload;
      }).catch(function (error) {
        console.log(error);
        payload = error;
        return payload;
      })
    )
  }
}

export const getbase64 = (payload) => {
  return (dispatch) => {
    return( axios.get(payload.imageUrl, { 
      responseType:"blob" 
    }).then(function (response) {
      var reader = new window.FileReader();      
      reader.onload = function() {
        return reader.result;  
          // var imageDataUrl = reader.result;
          // imageElement.setAttribute("src", imageDataUrl);
      }
      reader.readAsDataURL(response.data); 
    }).catch((error)=>{
      let payload = error;
        return payload;
    })
    )
  }
}

export const getFrame = (payload) => {
  return (dispatch) => {
    return( axios.get(BASE_URL+BASE_VERSION+'frame/'+payload.id, { 
        headers: { 
          Authorization: "Bearer "+window.localStorage.getItem('app_pt') 
        }, validateStatus: () => true
      }).then(function (response) {
        let payload = response.data;
        return payload;
      }).catch(function (error) {
        let payload = error;
        return payload;
      })
    )
  }
}

export const getFrameAll = (payload) => {
  return (dispatch) => {
    return( axios.get(BASE_URL+BASE_VERSION+'frame/all', { 
        params:payload,
        headers: { 
          Authorization: "Bearer "+window.localStorage.getItem('app_pt') 
        }, validateStatus: () => true
      }).then(function (response) {
        let payload = response.data;
        return payload;
      }).catch(function (error) {
        let payload = error;
        return payload;
      })
    )
  }
}

export const getFrameSearch = (payload) => {
  return (dispatch) => {
    return( axios.get(BASE_URL+BASE_VERSION+'frame/search', {
        params:payload, 
        headers: { 
          Authorization: "Bearer "+window.localStorage.getItem('app_pt') 
        }, validateStatus: () => true
      }).then(function (response) {
        let payload = response.data;
        return payload;
      }).catch(function (error) {
        let payload = error;
        return payload;
      })
    )
  }
}

export const deleteFrame = (payload) => {
  return (dispatch) => {
    return( axios.delete(BASE_URL+BASE_VERSION+'frame/'+payload.id, { 
        headers: { 
          Authorization: "Bearer "+window.localStorage.getItem('app_pt') 
        }, validateStatus: () => true
      }).then(function (response) {
        let payload = response.data;
        return payload;
      }).catch(function (error) {
        let payload = error;
        return payload;
      })
    )
  }
}