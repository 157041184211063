import React, { Component } from 'react';
import { connect } from "react-redux";
import {
    withRouter
} from "react-router-dom";

import { format, formatDistanceToNow, isBefore} from 'date-fns';

import { getCouponAll, getCouponSearch } from "../redux/actions/coupons";
import { getUserAll } from "../redux/actions/users";

import {
    Select,
    MenuItem,
    TextField,
    InputLabel,
    FormControl,
    capitalize
} from '@mui/material';

import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
// import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';

class CouponAll extends Component {
    constructor(props) {
        super(props);
        this.state = {
            filter_code:"",
            filter_category:0,
            filter_iduser:0,
            filter_description:"",
            filter_perpage:0,
            perpage:[ 30, 50, 100, 250, 500, 1000],
            categories:['retake', 'operator', 'ops', 'event', 'booth', 'activation'],
            users:[],
            data:null,
            err:null,
            is_loading:false
        };

        this.handleFilterSubmit = this.handleFilterSubmit.bind(this);
        this.handleChangeFilter = this.handleChangeFilter.bind(this);
    }

    handleChangeFilter(e){
        this.setState({[e.target.name]:e.target.value})
    }

    handleFilterSubmit(e){
        this.setState({is_loading:true});
        e.preventDefault();
        
        let params = {};
        if(this.state.filter_code !== ""){
            params.code = this.state.filter_code;
        }
        if(this.state.filter_category !== 0){
            params.category = this.state.filter_category;
        }
        if(this.state.filter_iduser !== 0){
            params.iduser = this.state.filter_iduser;
        }
        if(this.state.filter_description !== ""){
            params.description = this.state.filter_description;
        }
        
        params.perpage = this.state.perpage[this.state.filter_perpage];

        this.props.getCouponSearch(params).then((e)=>{
            this.setState({data:e.data, is_loading:false});
        })
    }

    componentDidMount(){
        var ctx = this;
        this.props.getUserAll().then((e)=>{
            this.setState({users:e.data});
        })

        this.props.getCouponAll({perpage:30}).then((e)=>{
            if(e.code === 200){
                ctx.setState({data:e.data});
            }else{
                ctx.setState({err:e.message});
            }
        }).catch((e)=>{
            alert("Something Error");
        });
    }

    render() {
        const url = this.props.match.url;
        var tableBody;
        if(this.state.data !== null){
            tableBody = this.state.data.map((item, idx)=>{
                return (
                <TableRow hover role="checkbox" tabIndex={-1} key={idx}>
                    <TableCell align="center" sx={{ width:"1%", whiteSpace: "nowrap" }}>
                        {idx += 1}
                    </TableCell>
                    <TableCell align="center">
                        <Typography variant="body2" sx={{display:"block", fontWeight:"bold"}}>{item.code}</Typography>
                    </TableCell>
                    <TableCell align="center">
                        <Typography variant="body2" sx={{display:"block", textTransform:"uppercase"}}>{item.coupon_type}</Typography>
                    </TableCell>                    
                    <TableCell align="center" sx={{ width:"1%", whiteSpace: "nowrap" }}>
                        {item.qty}
                    </TableCell>
                    <TableCell align="left" sx={{ width:"1%", whiteSpace: "nowrap" }}>
                        {item.description}
                    </TableCell>
                    <TableCell align="center">
                        {isBefore(new Date(item.valid_end.replace(/-/g, "/")), new Date())?
                        <Typography sx={{display:"block", color:"#ff0000"}}>Expired</Typography>
                        :""}
                        {formatDistanceToNow(new Date(item.valid_end.replace(/-/g, "/")), {addSuffix: true})}
                        {/* {formatDistance(subDays(new Date(item.valid_end.replace(/-/g, "/")), 0), new Date(), { addSuffix: true })} */}
                    </TableCell>
                    <TableCell align="center" sx={{ width:"1%", whiteSpace: "nowrap" }}>
                        <Typography variant="body2" sx={{display:"block"}}>{format(new Date(item.valid_start.replace(/-/g, "/")),"HH:mm - dd/MMM/yyyy")}</Typography>
                        <Typography variant="body2">{format(new Date(item.valid_end.replace(/-/g, "/")),"HH:mm - dd/MMM/yyyy")}</Typography>
                    </TableCell>
                    <TableCell align="center" sx={{ width:"70px", whiteSpace: "nowrap" }}>
                        <Grid container gap={1} alignItems="center">
                            <Button variant="contained" color="warning" onClick={(e)=>{
                                this.props.history.push(url+"/edit/"+item.code);
                            }}>Edit</Button>
                        </Grid>
                    </TableCell>
                </TableRow>
                )
            })
        }

        return (
            <Container className='main-panel booth'>
                <Grid container className="header-panel" alignItems="center" mb={1}>
                    <Grid item md={6} xs={6} sx={{justifyContent:"flex-start"}}>
                        <Typography variant="h6">{!this.props.title?"":this.props.title}</Typography>
                    </Grid>
                    <Grid item md={6} xs={6} sx={{textAlign:"right"}}>
                        <Button variant="contained" color="primary" onClick={(e)=>{
                                this.props.history.push(url+"/add");
                            }}>Add Voucher</Button>
                    </Grid>
                </Grid>
                <Grid container className="header-panel" alignItems="center" sx={{ paddingTop: "10px",paddingBottom: "10px", marginBottom:"10px" }}>
                    <Grid item md={12} sx={{display:"flex", gap:"5px", justifyContent:"flex-start", flexWrap:"wrap"}}>
                        <FormControl sx={{ minWidth: 120 }}>
                            <InputLabel id="select-user-label">User</InputLabel>
                            <Select
                                size="small"
                                label="User"
                                name="filter_iduser"
                                id="select-user"
                                labelId="select-user-label"
                                onChange={this.handleChangeFilter}
                                value={this.state.filter_iduser}
                            >
                                <MenuItem value={0} key={-1}>All</MenuItem>
                                {this.state.users.map((item)=>{
                                    return <MenuItem value={item.id} key={item.id}>{item.fullname || item.username}</MenuItem>
                                })}
                            </Select>
                        </FormControl>

                        <FormControl sx={{ minWidth: 120 }}>
                            <InputLabel id="select-category-label">Categories</InputLabel>
                            <Select
                                size="small"
                                label="Categories"
                                name="filter_category"
                                id="select-category"
                                labelId="select-category-label"
                                onChange={this.handleChangeFilter}
                                value={this.state.filter_category}
                            >
                                <MenuItem value={0} key={-1}>All</MenuItem>
                                {this.state.categories.map((item)=>{
                                    return <MenuItem value={item} key={item}>{capitalize(item)}</MenuItem>
                                })}
                            </Select>
                        </FormControl>
                        <TextField 
                            size="small"
                            id="input-code"
                            name="filter_code"
                            label="Voucher Code"                            
                            placeholder="xbdobintangxxx"
                            // sx={{height:"8px"}}
                            onChange={this.handleChangeFilter}
                            value={this.state.filter_code}
                        >
                        </TextField>                        
                        <TextField 
                            size="small"
                            id="input-description"
                            name="filter_description"
                            label="Description"
                            placeholder="Event Jakarta"
                            // sx={{height:"8px"}}
                            onChange={this.handleChangeFilter}
                            value={this.state.filter_description}
                        >
                        </TextField>
                        <FormControl>
                            <InputLabel id="select-perpage-label">Per Page</InputLabel>
                            <Select
                                size="small"
                                label="Perpage"
                                name="filter_perpage"
                                id="select-perpage"
                                labelId="select-perpage-label"
                                onChange={this.handleChangeFilter}
                                value={this.state.filter_perpage}
                                sx={{width:"100px"}}
                            >
                                {/* <MenuItem value={0} key={-1}>25</MenuItem> */}
                                {this.state.perpage.map((item, idx)=>{
                                    return <MenuItem value={idx} key={idx}>{item}</MenuItem>
                                })}
                            </Select>
                        </FormControl>
                        {!this.state.is_loading?
                        <Button variant="contained" onClick={this.handleFilterSubmit}>Filter</Button>:
                        <Typography className="btn-loading-filter">HARAP SABAR...</Typography>}
                        {/* <Button variant='contained' sx={{marginRight:"5px"}} onClick={this.handleFilterSubmit}>Filter</Button> */}
                    </Grid>
                    {/* <Grid item md={3} sx={{textAlign:"right"}}>
                        
                    </Grid> */}
                </Grid>
                <Paper>
                    <TableContainer sx={{ maxHeight: 570 }}>
                        <Table stickyHeader aria-label="sticky table">
                        <TableHead>
                            <TableRow>
                                <TableCell>No.</TableCell>
                                <TableCell align="center">Code</TableCell>
                                <TableCell align="center">Type</TableCell>
                                <TableCell align="center">Quantity</TableCell>
                                <TableCell align="left">Description</TableCell>
                                <TableCell align="center">Expired In</TableCell>
                                <TableCell align="center">Validity</TableCell>
                                <TableCell align="center">Action</TableCell>                                
                            </TableRow>
                        </TableHead>
                        <TableBody>                        
                            {tableBody}
                        </TableBody>
                        </Table>
                    </TableContainer>
                    {/* <TablePagination
                        rowsPerPageOptions={[10, 25, 100]}
                        component="div"
                        count={10}
                        rowsPerPage={15}
                        page={10}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    /> */}
                </Paper>
            </Container>            
        )
    }
}

function mapDispatchToProps(dispatch) {
    return {
        getUserAll: data => dispatch(getUserAll(data)),
        getCouponAll: data => dispatch(getCouponAll(data)),
        getCouponSearch: data => dispatch(getCouponSearch(data))  
    };
}

const mapStateToProps = state => ({
    ...state
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CouponAll));