import axios from 'axios';
import {BASE_URL, BASE_VERSION} from "../config";

export const addCoupon = (payload) => {
  return (dispatch) => {
    return( axios.post(BASE_URL+BASE_VERSION+'coupon', {
        code:payload.code,
        coupon_type:payload.coupon_type, //['free', 'admin_fee', 'percentage', 'amount']
        qty:payload.qty,
        description:payload.description,
        valid_start:payload.valid_start,
        valid_end:payload.valid_end,
        is_active:payload.is_active,
        loop:payload.loop,
        iduser:payload.iduser,
        coupon_category:payload.coupon_category
      },{ 
        headers: { 
          Authorization: "Bearer "+window.localStorage.getItem('app_pt') 
        }
      }).then(function (response) {
        console.log(response);
        payload = response.data;        
        return payload;
      }).catch(function (error) {
        console.log(error);
        payload = error;
        return payload;
      })
    )
  }
}

export const updateCoupon = (payload) => {
  return (dispatch) => {
    return( axios.put(BASE_URL+BASE_VERSION+'coupon/'+payload.id, {
        code:payload.code,
        coupon_type:payload.coupon_type, //['free', 'admin_fee', 'percentage', 'amount']
        qty:payload.qty,
        description:payload.description,
        valid_start:payload.valid_start,
        valid_end:payload.valid_end,
        is_active:payload.is_active,
        iduser:payload.iduser,
        coupon_category:payload.coupon_category
      },{ 
        headers: { 
          Authorization: "Bearer "+window.localStorage.getItem('app_pt') 
        }
      }).then(function (response) {
        console.log(response);
        payload = response.data;
        return payload;
      }).catch(function (error) {
        console.log(error);
        payload = error;
        return payload;
      })
    )
  }
}

export const getCoupon = (payload) => {
  return (dispatch) => {
    return( axios.get(BASE_URL+BASE_VERSION+'coupon/'+payload.code, { 
        headers: { 
          Authorization: "Bearer "+window.localStorage.getItem('app_pt') 
        }, validateStatus: () => true
      }).then(function (response) {
        let payload = response.data;
        return payload;
      }).catch(function (error) {
        let payload = error;
        return payload;
      })
    )
  }
}

export const getCouponAll = (payload) => {
  return (dispatch) => {
    return( axios.get(BASE_URL+BASE_VERSION+'coupon/all', { 
        params:payload,
        headers: { 
          Authorization: "Bearer "+window.localStorage.getItem('app_pt') 
        }, validateStatus: () => true
      }).then(function (response) {
        let payload = response.data;
        return payload;
      }).catch(function (error) {
        let payload = error;
        return payload;
      })
    )
  }
}

export const getCouponSearch = (payload) => {
  return (dispatch) => {
    return( axios.get(BASE_URL+BASE_VERSION+'coupon/search', { 
        params:payload,
        headers: { 
          Authorization: "Bearer "+window.localStorage.getItem('app_pt') 
        }, validateStatus: () => true
      }).then(function (response) {
        let payload = response.data;
        return payload;
      }).catch(function (error) {
        let payload = error;
        return payload;
      })
    )
  }
}

export const deleteCoupon = (payload) => {
  return (dispatch) => {
    return( axios.delete(BASE_URL+BASE_VERSION+'coupon/'+payload.id, { 
        headers: { 
          Authorization: "Bearer "+window.localStorage.getItem('app_pt') 
        }, validateStatus: () => true
      }).then(function (response) {
        let payload = response.data;
        return payload;
      }).catch(function (error) {
        let payload = error;
        return payload;
      })
    )
  }
}