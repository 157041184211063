import React, { Component } from 'react';
import { connect } from "react-redux";
import {
    // Link,
    withRouter
} from "react-router-dom";

import { getCoupon, updateCoupon, deleteCoupon } from "../redux/actions/coupons";
import { getUserAll } from "../redux/actions/users";

import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import Switch from '@mui/material/Switch';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';

import {
    Select,
    MenuItem,
    TextField,
    InputLabel,
    FormControl,
    FormGroup, 
    FormControlLabel,
    FormHelperText,
    capitalize,
} from '@mui/material';

class CouponEdit extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data:{
                id:"",
                code:"",
                coupon_type:"", //['free', 'admin_fee', 'percentage', 'amount']
                qty:1,
                description:"",
                valid_start:"",
                valid_end:"",
                is_active:true,
                iduser:1,
                coupon_category:""
                // discount_percentage:"",
                // discount_amount:"",
            },
            categories:['event', 'retake', 'operator', 'ops', 'booth', 'activation'],
            users:null,
            err:null
        };

        this.handleDelete = this.handleDelete.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleDelete(e){
        e.preventDefault()
        const answer = window.confirm("Are you sure to delete?");
        if (answer) {
            var ctx = this;
            this.props.deleteCoupon({id:this.state.data.id}).then((e)=>{
                if(e.code === 200){
                    ctx.props.history.replace("/dashboard/coupon");
                }else{
                    alert("Something Error");
                }
            }).catch((err)=>{
                console.log(err);
            })
        }
    }

    handleChange(e){
        let tmpVal = e.target.value;
        if(e.target.name === "is_active"){
            tmpVal = JSON.parse(e.target.checked);
        }
        this.setState({
            data: {
                ...this.state.data,
                [e.target.name]: tmpVal
            }
        });
    }

    handleSubmit(e){
        e.preventDefault();
        const answer = window.confirm("Are you sure?");
        if (answer) {
            var ctx = this;
            this.props.updateCoupon({
                id:ctx.state.data.id,
                code:ctx.state.data.code,
                coupon_type:ctx.state.data.coupon_type, //['free', 'admin_fee', 'percentage', 'amount']
                qty:parseInt(ctx.state.data.qty),
                description:ctx.state.data.description,
                valid_start:ctx.state.data.valid_start,
                valid_end:ctx.state.data.valid_end,
                is_active:ctx.state.data.is_active,
                iduser:ctx.state.data.iduser,
                coupon_category:ctx.state.data.coupon_category
            }).then((e)=>{
                if(e.code === 200){
                    ctx.props.history.replace("/dashboard/coupon");
                }else{
                    alert(e.message);
                }
            }).catch((err)=>{
                console.log(err);
                alert(err.message);
            });
        }else{
            console.log("dismiss!");
        }
    }

    componentDidMount(){
        var ctx = this;
        this.props.getUserAll().then((e)=>{
            this.setState({users:e.data});
        })

        this.props.getCoupon({code:this.props.match.params.code}).then((e)=>{
            if(e.code === 200){
                let dataRaw = e.data;
                dataRaw.iduser = e.data.coupon_issuer;
                ctx.setState({data:dataRaw});
            }else{
                alert("Something Error");
            }
        }).catch((err)=>{
            console.log(err);
        })
    }

    render() {        
        return (
            <Container className='main-panel coupon'>                
                <Box mt={3}>
                    <Paper component="form" className='panel-container-box' onSubmit={this.handleSubmit}>
                    <Typography variant="h5" mt={1} mb={2}>Edit Voucher</Typography>
                    <FormGroup>
                        <TextField
                            size="small"
                            id="input-couponcode"
                            name="code"
                            label="Code"
                            helperText="Huruf kecil semua, 5-10 character"
                            value={this.state.data.code}
                            onChange={this.handleChange}                            
                            sx={{marginBottom:"20px"}}
                        />

{this.state.users !== null?
                        <FormControl sx={{ minWidth: 120 }}>
                            <InputLabel id="select-user-label">User</InputLabel>
                            <Select
                                size="small"
                                labelId="select-user-label"
                                id="select-user"
                                name="iduser"
                                value={this.state.data.iduser}
                                label="ID User"
                                onChange={this.handleChange}
                            >
                                {this.state.users.map((item)=>{
                                    return <MenuItem value={item.id} key={item.id}>{capitalize(item.fullname || item.username)}</MenuItem>
                                })}
                            </Select>
                            <FormHelperText sx={{marginBottom:"20px"}}>Voucher Dibuat Oleh</FormHelperText>
                        </FormControl>
                        :<Typography>Loading...</Typography>
                        }

                        <FormControl sx={{ minWidth: 120 }}>
                            <InputLabel id="select-user-label">Voucher Category</InputLabel>
                            <Select
                                size="small"
                                labelId="select-coupon_category-label"
                                id="select-coupon_category"
                                name="coupon_category"
                                value={this.state.data.coupon_category}
                                label="Coupon Category"
                                onChange={this.handleChange}
                            >
                                {this.state.categories.map((item, idx)=>{
                                    return <MenuItem value={item} key={idx}>{capitalize(item)}</MenuItem>
                                })}
                            </Select>
                            <FormHelperText sx={{marginBottom:"20px"}}>Voucher Category</FormHelperText>
                        </FormControl>

                        <TextField
                            size="small"
                            id="input-coupondescription"
                            name="description"
                            label="Coupon Description"
                            helperText="Deskripsi Coupon"
                            value={this.state.data.description!==null?this.state.data.description:""}
                            onChange={this.handleChange}
                            multiline
                            rows={3}
                            sx={{marginBottom:"20px"}}
                        />

                        <TextField
                            size="small"
                            id="input-couponqty"
                            name="qty"
                            label="Quantity"
                            helperText="1 - 999"
                            type="number"
                            value={this.state.data.qty}
                            onChange={this.handleChange}
                            required
                            sx={{marginBottom:"20px"}}
                        />

                        <TextField
                            size="small"
                            id="input-couponvalid_start"
                            name="valid_start"
                            label="Valid Start"
                            helperText="Tahun-Bulan-Tanggal jam:menit:detik"
                            value={this.state.data.valid_start!==null?this.state.data.valid_start:""}
                            onChange={this.handleChange}
                            sx={{marginBottom:"20px"}}
                        />

                        <TextField
                            size="small"
                            id="input-couponvalid_end"
                            name="valid_end"
                            label="Valid End"
                            helperText="Tahun-Bulan-Tanggal jam:menit:detik"
                            value={this.state.data.valid_end!==null?this.state.data.valid_end:""}
                            onChange={this.handleChange}
                            sx={{marginBottom:"20px"}}
                        />
                        
                        <FormControlLabel 
                            control={<Switch checked={this.state.data.is_active}/>} 
                            label="Active"
                            name="is_active"
                            value={this.state.data.is_active}
                            onChange={this.handleChange}
                            sx={{marginBottom:"20px", marginLeft:"5px"}}
                        />

                        <Button type="submit" variant="contained" sx={{marginBottom:"10px"}}>Update</Button>
                        {/* <Button onClick={this.handleDelete} variant="contained" color="error">Delete</Button> */}
                    </FormGroup>
                    </Paper>
                </Box>
            </Container>            
        )
    }
}

function mapDispatchToProps(dispatch) {
    return {
        getCoupon: data => dispatch(getCoupon(data)),
        getUserAll: data => dispatch(getUserAll(data)),
        updateCoupon: data => dispatch(updateCoupon(data)),
        deleteCoupon: data => dispatch(deleteCoupon(data))        
    };
}

const mapStateToProps = state => ({
    ...state
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CouponEdit));