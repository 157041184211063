import React, { Component } from 'react';
import { connect } from "react-redux";
import {
    // Link,
    withRouter
} from "react-router-dom";

import { getBrandAll } from "../redux/actions/brands";

// import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
// import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';

class BrandAll extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data:null,
            err:null
        };
    }

    componentDidMount(){
        var ctx = this;
        this.props.getBrandAll().then((e)=>{
            if(e.code === 200){
                ctx.setState({data:e.data});
            }else{
                ctx.setState({err:e.message});
            }
        }).catch((e)=>{
            alert("Something Error");
        })
    }

    render() {
        const url = this.props.match.url;
        var tableBody;

        if(this.state.data !== null){
            tableBody = this.state.data.map((item, idx)=>{
                return (
                <TableRow hover role="checkbox" tabIndex={-1} key={idx}>
                    <TableCell sx={{ width:"1%", whiteSpace: "nowrap" }}>
                        {idx += 1}
                    </TableCell>
                    <TableCell align="left">
                        {item.name}
                    </TableCell>                    
                    <TableCell align="left">
                        {item.description}
                    </TableCell>
                    <TableCell align="center" sx={{ width:"1%", whiteSpace: "nowrap" }}>
                        {item.is_active===true?"Active":"Not Active"}
                    </TableCell>
                    <TableCell align="center" sx={{ width:"70px", whiteSpace: "nowrap" }}>
                        <Grid container gap={1} alignItems="center">
                            <Button variant="contained" color="warning" onClick={(e)=>{
                                this.props.history.push(url+"/edit/"+item.id);
                            }}>Edit</Button>
                        </Grid>
                    </TableCell>
                </TableRow>
                )
            })
        }

        return (
            <Container className='main-panel booth'>
                <Grid container className="header-panel" alignItems="center" mb={1}>
                    <Grid item md={6} xs={6} sx={{justifyContent:"flex-start"}}>
                        <Typography variant="h6">{!this.props.title?"":this.props.title}</Typography>
                    </Grid>
                    <Grid item md={6} xs={6} sx={{textAlign:"right"}}>
                        <Button variant="contained" color="primary" onClick={(e)=>{
                                this.props.history.push(url+"/add");
                            }}>Add Brand</Button>
                    </Grid>
                </Grid>
                <Paper>
                    <TableContainer sx={{ maxHeight: 570 }}>
                        <Table stickyHeader aria-label="sticky table">
                        <TableHead>
                            <TableRow>
                                <TableCell>No.</TableCell>
                                <TableCell align="left">Name</TableCell>
                                <TableCell align="left">Description</TableCell>
                                <TableCell align="center">Status</TableCell>
                                <TableCell align="center">Action</TableCell>                                
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {tableBody}                            
                        </TableBody>
                        </Table>
                    </TableContainer>                    
                </Paper>
            </Container>            
        )
    }
}

function mapDispatchToProps(dispatch) {
    return {
        getBrandAll: data => dispatch(getBrandAll(data))
    };
}

const mapStateToProps = state => ({
    ...state
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(BrandAll));