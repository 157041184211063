import React, { Component } from 'react';
import { connect } from "react-redux";
import {
    withRouter
} from "react-router-dom";

import { getFrameAll, deleteFrame, getFrameSearch} from "../redux/actions/frames";
import { getBrandAll } from "../redux/actions/brands";

import {
    Select,
    MenuItem,
    TextField,
    InputLabel,
    FormControl
} from '@mui/material';

import Grid from '@mui/material/Grid';
import Chip from '@mui/material/Chip';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';

import Table from '@mui/material/Table';
import TableRow from '@mui/material/TableRow';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableContainer from '@mui/material/TableContainer';

class FrameAll extends Component {
    constructor(props) {
        super(props);
        this.state = {
            filter_idbrand:0,
            filter_name:"",
            filter_perpage:0,
            perpage:[ 30, 50, 100, 250, 500, 1000],
            brand:[],
            data:null,
            err:null,
            is_loading:false
        };

        this.handleDelete = this.handleDelete.bind(this);
        this.handleFilterSubmit = this.handleFilterSubmit.bind(this);
        this.handleChangeFilter = this.handleChangeFilter.bind(this);
    }

    handleChangeFilter(e){
        this.setState({[e.target.name]:e.target.value})
    }

    handleFilterSubmit(e){
        this.setState({is_loading:true});
        e.preventDefault();

        let params = {};
        if(this.state.filter_idbrand !== 0){
            params.idbrand = this.state.filter_idbrand;
        }
        if(this.state.filter_name !== ""){
            params.name = this.state.filter_name;
        }

        params.perpage = this.state.perpage[this.state.filter_perpage];

        this.props.getFrameSearch(params).then((e)=>{
            if(e.code === 200){
                this.setState({data:e.data, is_loading:false});
            }else{
                this.setState({data:[], is_loading:false});
            }
        })
    }

    handleDelete(e){
        e.preventDefault();
        var confirmed = window.confirm("Are you sure to delete this frame?");
        if(confirmed){
            this.props.deleteFrame({id:e.target.dataset.idframe}).then((e)=>{
                if(e.code === 200){
                    this.loadData();
                    alert("Delete Success");
                }else{
                    alert("Something Error : "+e.message);
                }
            }).catch((err)=>{
                console.log(err);
                alert("Something Error : "+e.message);
            })
        }
    }

    loadData(){
        var ctx = this;
        this.props.getBrandAll().then((e)=>{
            if(e.code === 200){
                this.setState({brand:e.data});                
            }
        });

        this.props.getFrameAll({perpage:30}).then((e)=>{
            if(e.code === 200){
                ctx.setState({data:e.data});
            }else{
                ctx.setState({err:e.message});
            }
        }).catch((e)=>{
            alert("Something Error");
        })
    }

    componentDidMount(){
        this.loadData()
    }

    render() {
        const url = this.props.match.url;
        var tableBody;

        if(this.state.data !== null){
            tableBody = this.state.data.map((item, idx)=>{
                return (
                <TableRow hover role="checkbox" tabIndex={-1} key={idx}>
                    <TableCell sx={{ width:"1%", whiteSpace: "nowrap" }}>
                        {idx += 1}
                    </TableCell>
                    <TableCell align="center">
                        <img alt="full-cover-thumbnail" src={item.fullurl_image} width="50"/>
                    </TableCell>
                    <TableCell align="left">
                        {item.brand !== null?<Chip label={item.brand?.name} className={"brand-color-"+item.brand?.id} size="small" />:
                        <Typography sx={{fontSize:"10px", textAlign:"center"}}>Need a Brand!</Typography>}
                    </TableCell>
                    <TableCell align="left">
                        {item.name}
                    </TableCell>
                    <TableCell>
                        {item.description}
                    </TableCell>
                    <TableCell align="center" sx={{ width:"1%", whiteSpace: "nowrap" }}>
                        {item.is_active === true?"Active":"Not Active"}
                    </TableCell>
                    <TableCell align="center" 
                        sx={{ 
                            whiteSpace: "nowrap", 
                        }}>
                        <Grid container gap={1} alignItems="center">
                            <Button variant="contained" color="primary" onClick={(e)=>{
                                this.props.history.push(url+"/edit/"+item.slug);
                            }}>Edit</Button>
                            {/* <Button variant="contained" color="error" data-idframe={item.id} onClick={this.handleDelete}>Delete</Button> */}
                        </Grid>
                    </TableCell>
                </TableRow>
                )
            })
        }

        return (
            <Container className='main-panel booth'>
                <Grid container className="header-panel" alignItems="center" mb={1}>
                    <Grid item md={6} xs={6} sx={{justifyContent:"flex-start"}}>
                        <Typography variant="h6">{!this.props.title?"":this.props.title}</Typography>
                    </Grid>
                    <Grid item md={6} xs={6} sx={{textAlign:"right"}}>
                        <Button alt="Test Experimental" variant="contained" color="primary" sx={{marginRight:"10px"}} onClick={(e)=>{
                                this.props.history.push(url+"/addv2");
                        }}>Add Frame</Button>
                        {/* <Button variant="contained" color="primary" onClick={(e)=>{
                                this.props.history.push(url+"/add");
                        }}>Add Frame</Button> */}
                    </Grid>
                </Grid>
                <Grid container className="header-panel" alignItems="center" sx={{ paddingTop: "10px",paddingBottom: "10px", marginBottom:"10px" }}>
                    <Grid item md={12} xs={12} sx={{display:"flex", gap:"5px", justifyContent:"flex-start", flexWrap: {xs:"wrap", md:"nowrap"}}}>
                        <FormControl sx={{ minWidth: 120 }}>
                            <InputLabel id="select-brand-label">Brand</InputLabel>
                            <Select
                                size="small"
                                label="Brand"
                                name="filter_idbrand"
                                id="select-brand"
                                labelId="select-brand-label"
                                onChange={this.handleChangeFilter}
                                value={this.state.filter_idbrand}
                            >
                                <MenuItem value={0} key={-1}>All</MenuItem>
                                {this.state.brand.map((item)=>{
                                    return <MenuItem value={item.id} key={item.id}>{item.name}</MenuItem>
                                })}
                            </Select>
                        </FormControl>                        
                        <TextField 
                            size="small"
                            id="input-filtername"
                            name="filter_name"
                            label="Filter Name"
                            placeholder="Signature"
                            // sx={{height:"8px"}}
                            onChange={this.handleChangeFilter}
                            value={this.state.filter_name}
                        >
                        </TextField>
                        <FormControl>
                            <InputLabel id="select-perpage-label">Per Page</InputLabel>
                            <Select
                                size="small"
                                label="Perpage"
                                name="filter_perpage"
                                id="select-perpage"
                                labelId="select-perpage-label"
                                onChange={this.handleChangeFilter}
                                value={this.state.filter_perpage}
                                sx={{width:"100px"}}
                            >
                                {/* <MenuItem value={0} key={-1}>25</MenuItem> */}
                                {this.state.perpage.map((item, idx)=>{
                                    return <MenuItem value={idx} key={idx}>{item}</MenuItem>
                                })}
                            </Select>
                        </FormControl>
                        {!this.state.is_loading?
                        <Button variant="contained" onClick={this.handleFilterSubmit}>Filter</Button>:
                        <Typography className="btn-loading-filter">HARAP SABAR...</Typography>}
                        {/* <Button variant="contained" onClick={this.handleFilterSubmit}>Filter</Button> */}
                    </Grid>
                    {/* <Grid item md={3} sx={{textAlign:"right"}}> */}
                        {/* <Typography sx={{marginLeft:"5px"}}>Sort By</Typography> */}
                    {/* </Grid> */}
                </Grid>
                <Paper>
                    <TableContainer sx={{ maxHeight: 570 }}>
                        <Table stickyHeader aria-label="sticky table">
                        <TableHead>
                            <TableRow>
                                <TableCell>No.</TableCell>
                                <TableCell align="center">Preview</TableCell>
                                <TableCell align="left">Brand</TableCell>
                                <TableCell align="left">Name</TableCell>
                                <TableCell align="left">Description</TableCell>
                                <TableCell align="center">Status</TableCell>
                                <TableCell align="center">Action</TableCell>                                
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {tableBody}                            
                        </TableBody>
                        </Table>
                    </TableContainer>
                    {/* <TablePagination
                        rowsPerPageOptions={[10, 25, 100]}
                        component="div"
                        count={10}
                        rowsPerPage={15}
                        page={10}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    /> */}
                </Paper>
            </Container>            
        )
    }
}

function mapDispatchToProps(dispatch) {
    return {
        getBrandAll: data => dispatch(getBrandAll(data)),
        getFrameAll: data => dispatch(getFrameAll(data)),
        deleteFrame: data => dispatch(deleteFrame(data)),
        getFrameSearch: data => dispatch(getFrameSearch(data))
    };
}

const mapStateToProps = state => ({
    ...state
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(FrameAll));