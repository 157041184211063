import React, { Component } from 'react';
import { connect } from "react-redux";
import {
    withRouter
} from "react-router-dom";

import { format } from 'date-fns';

import { CSVLink } from "react-csv"; //CSVDownload

import { getSales, getSalesSearch } from "../redux/actions/sales";
import { getBrandAll } from "../redux/actions/brands";
import { getBoothAll, getBoothSearch } from "../redux/actions/booths";

import {
    Select,
    MenuItem,
    TextField,
    InputLabel,
    FormControl
} from '@mui/material';

import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

class SalesAll extends Component {
    constructor(props) {
        super(props);
        this.state = {
            filter_idbrand:0,
            filter_idbooth:0,
            filter_status:0,
            filter_idorder:"",
            filter_description:"",
            filter_startdate:"",
            filter_enddate:"",
            filter_perpage:0,
            perpage:[ 30, 50, 100, 250, 500, 1000, 2500, 3000],
            brand:[],
            booth:[],
            status:["All", "COMPLETED", "PENDING", "REFUND"],
            data:null,
            data_cleaned:null,
            data_perday:null,
            err:null,
            is_loading:false
        };

        this.handleFilterSubmit = this.handleFilterSubmit.bind(this);
        this.handleChangeFilter = this.handleChangeFilter.bind(this);
        this.generateDataCSV = this.generateDataCSV.bind(this);
    }

    generateDataCSV(data){
        let dataTmp = data;
        let data_cleanedtmp = [];
        let data_perdaytmp = [];
        
        if(dataTmp !== null){            
            for(let i = 0; i < dataTmp.length; i++){                    
                delete dataTmp[i].signaturekey;
                delete dataTmp[i].qrcode;
                delete dataTmp[i].qrcode_id;
                delete dataTmp[i].vacode;
                delete dataTmp[i].quantity;
                delete dataTmp[i].paymentfee;
                delete dataTmp[i].tax;
                delete dataTmp[i].discount;
                delete dataTmp[i].is_active;
                delete dataTmp[i].updated_at;
                delete dataTmp[i].iduser;
                
                dataTmp[i] = {...{no:i+1}, ...dataTmp[i]};
                data_cleanedtmp.push(dataTmp[i]);

                let total = dataTmp[i].total
                let date = dataTmp[i].created_at.split(' ')[0].replace(/-/g, "-");
                let indexPerday = data_perdaytmp.findIndex(x => x.date === date);

                if(indexPerday === -1){
                    data_perdaytmp.push({
                        date:date,
                        total:total,
                        total_format:"IDR"+total.toLocaleString("id"),
                    })
                }else{
                    data_perdaytmp[indexPerday].total += dataTmp[i].total;
                    data_perdaytmp[indexPerday].total_format = "IDR"+data_perdaytmp[indexPerday].total.toLocaleString("id");
                }
            }
            this.setState({
                data_cleaned:data_cleanedtmp,
                data_perday:data_perdaytmp
            })
        }else{
            this.setState({
                data_cleaned:null,
                data_perday:null,
                data:null
            });
            console.log("No Data");
        }
    }

    handleChangeFilter(e){
        if(e.target.name === "filter_idbrand"){
            if(e.target.value !== 0){
                this.props.getBoothSearch({idbrand:e.target.value}).then((e)=>{
                    if(e.code === 200){
                        this.setState({booth:e.data});                
                    }
                });
            }else{
                this.props.getBoothAll().then((e)=>{
                    if(e.code === 200){
                        this.setState({booth:e.data});                
                    }
                });
            }
        }

        this.setState({[e.target.name]:e.target.value})
    }

    handleFilterSubmit(e){
        this.setState({is_loading:true});
        e.preventDefault();

        let params = {};
        if(this.state.filter_idbrand !== 0){
            params.idbrand = this.state.filter_idbrand;
        }
        if(this.state.filter_idbooth !== 0){
            params.idbooth = this.state.filter_idbooth;
        }
        if(this.state.filter_status !== 0){
            params.status = this.state.status[this.state.filter_status];
        }        
        if(this.state.filter_idorder !== ""){
            params.idorder = this.state.filter_idorder;
        }
        if(this.state.filter_description !== ""){
            params.description = this.state.filter_description;
        }
        if(this.state.filter_startdate !== ""){
            params.startdate = this.state.filter_startdate;
        }
        if(this.state.filter_enddate !== ""){
            params.enddate = this.state.filter_enddate;
        }

        params.perpage = this.state.perpage[this.state.filter_perpage];
        
        this.props.getSalesSearch(params).then((e)=>{
            if(e.code === 200){
                this.setState({data:e.data, is_loading:false});
                this.generateDataCSV(e.data);
            }else{
                this.setState({data:[], is_loading:false});
            }
        })
    }

    componentDidMount(){
        var ctx = this;
        this.props.getBrandAll().then((e)=>{
            if(e.code === 200){
                this.setState({brand:e.data});                
            }
        });
        this.props.getBoothAll().then((e)=>{
            if(e.code === 200){
                this.setState({booth:e.data});                
            }
        });

        this.props.getSales({perpage:50}).then((e)=>{
            if(e.code === 200){
                ctx.setState({data:e.data});
                this.generateDataCSV(e.data);
            }else{
                ctx.setState({err:e.message});
            }
        }).catch((e)=>{
            alert("Something Error");
        })
    }

    render() {
        var tableBody;
        if(this.state.data !== null){
            tableBody = this.state.data.map((item, idx)=>{                
                return (
                <TableRow hover role="checkbox" tabIndex={-1} key={idx}>
                    <TableCell sx={{ width:"1%", whiteSpace: "nowrap" }}>
                        {idx += 1}
                    </TableCell>
                    <TableCell align="left">
                        <Typography variant="body2" sx={{display:"block", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis"}}>{item.idorder}</Typography>
                    </TableCell>
                    {/* <TableCell align="center">
                        <Typography variant="body2" sx={{display:"block", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis"}}>{item.idbooth}</Typography>
                    </TableCell>
                    <TableCell align="center">
                        <Typography variant="body2" sx={{display:"block", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis"}}>{item.idframe}</Typography>
                    </TableCell>
                    <TableCell align="center">
                        <Typography variant="body2" sx={{display:"block", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis"}}>{item.idphoto}</Typography>
                    </TableCell> */}
                    <TableCell align="left">
                        IDR{item.total.toLocaleString("id")}
                    </TableCell>
                    <TableCell>
                        {item.description}
                    </TableCell>
                    <TableCell>
                        <span className='field-time'>{format(new Date(item.created_at.replace(/-/g, "/")),"yyyy-MM-dd HH:mm")}</span>
                        <span className='field-time'>{item.settledAt!==null?format(new Date(item.settledAt.replace(/-/g, "/")),"yyyy-MM-dd HH:mm"):"No Trans"}</span>
                    </TableCell>
                    <TableCell align="center" sx={{ width:"1%", whiteSpace: "nowrap" }}>
                        {item.status}
                    </TableCell>
                    <TableCell align="center" sx={{ width:"70px", whiteSpace: "nowrap" }}>
                        <Grid container gap={1} alignItems="center">
                            <Button variant="contained" color="primary">Detail</Button>
                        </Grid>
                    </TableCell>
                </TableRow>
                )
            })
        }

        return (
            <Container className='main-panel booth'>
                <Grid container className="header-panel" alignItems="center" mb={1}>
                    <Grid item md={6} sx={{justifyContent:"flex-start"}}>
                        <Typography variant="h6">{!this.props.title?"":this.props.title}</Typography>
                    </Grid>
                    <Grid item md={6} sx={{textAlign:"right"}} className="container-btn-export-report">
                        {this.state.data_perday !== null?
                        <CSVLink className="btn-export-report" filename={"report-raw-perday.csv"} data={this.state.data_perday}>DOWNLOAD PER DAY</CSVLink>:""}
                        {this.state.data_cleaned !== null?
                        <CSVLink className="btn-export-report" filename={"report-cleaned-data.csv"} data={this.state.data_cleaned}>DOWNLOAD DATA</CSVLink>:""}
                        {this.state.data !== null?
                        <CSVLink className="btn-export-report warning" filename={"report-raw-data.csv"} data={this.state.data}>DOWNLOAD RAW</CSVLink>:""}
                    </Grid>
                </Grid>
                <Grid container className="header-panel" alignItems="center" sx={{ paddingTop: "10px",paddingBottom: "10px", marginBottom:"10px" }}>
                    <Grid item md={12} sx={{display:"flex", gap:"10px", justifyContent:"flex-start", flexWrap: "wrap"}}>
                        <FormControl sx={{ minWidth: 120 }}>
                            <InputLabel id="select-brand-label">Brand</InputLabel>
                            <Select
                                size="small"
                                label="Brand"
                                name="filter_idbrand"
                                id="select-brand"
                                labelId="select-brand-label"
                                onChange={this.handleChangeFilter}
                                value={this.state.filter_idbrand}
                                sx={{marginBottom:{md:"10px"}}}
                            >
                                <MenuItem value={0} key={-1}>All</MenuItem>
                                {this.state.brand.map((item)=>{
                                    return <MenuItem value={item.id} key={item.id}>{item.name}</MenuItem>
                                })}
                            </Select>
                        </FormControl>  
                        <FormControl sx={{ minWidth: 120 }}>
                            <InputLabel id="select-brand-label">Booth</InputLabel>
                            <Select
                                size="small"
                                label="Booth"
                                name="filter_idbooth"
                                id="select-booth"
                                labelId="select-booth-label"
                                onChange={this.handleChangeFilter}
                                value={this.state.filter_idbooth}
                            >
                                <MenuItem value={0} key={-1}>All</MenuItem>
                                {this.state.booth.map((item)=>{
                                    return <MenuItem value={item.id} key={item.id}>{item.name}</MenuItem>
                                })}
                            </Select>
                        </FormControl>                        
                        <TextField 
                            size="small"
                            id="input-filter_idorder"
                            name="filter_idorder"
                            label="ID Order"
                            placeholder="ID Order"
                            // sx={{height:"8px"}}
                            onChange={this.handleChangeFilter}
                            value={this.state.filter_idorder}
                        >
                        </TextField>
                        <TextField 
                            size="small"
                            id="input-filter_description"
                            name="filter_description"
                            label="Description"
                            placeholder="Description"
                            // sx={{height:"8px"}}
                            onChange={this.handleChangeFilter}
                            value={this.state.filter_description}
                        >
                        </TextField>
                        <TextField 
                            size="small"
                            id="input-filter_datestart"
                            name="filter_startdate"
                            label="Date Start"
                            placeholder="yyyy-MM-dd HH:mm"
                            // sx={{height:"8px"}}
                            onChange={this.handleChangeFilter}
                            value={this.state.filter_startdate}
                        >
                        </TextField>
                        <TextField 
                            size="small"
                            id="input-filter_dateend"
                            name="filter_enddate"
                            label="Date End"
                            placeholder="yyyy-MM-dd HH:mm"
                            // sx={{height:"8px"}}
                            onChange={this.handleChangeFilter}
                            value={this.state.filter_enddate}
                        >
                        </TextField>
                        <FormControl sx={{ minWidth: 120 }}>
                            <InputLabel id="select-status-label">Status</InputLabel>
                            <Select
                                size="small"
                                label="Status"
                                name="filter_status"
                                id="select-status"
                                labelId="select-status-label"
                                onChange={this.handleChangeFilter}
                                value={this.state.filter_status}
                            >
                                {this.state.status.map((item, idx)=>{
                                    return <MenuItem value={idx} key={idx}>{item}</MenuItem>
                                })}
                            </Select>
                        </FormControl>
                        <FormControl>
                            <InputLabel id="select-perpage-label">Per Page</InputLabel>
                            <Select
                                size="small"
                                label="Perpage"
                                name="filter_perpage"
                                id="select-perpage"
                                labelId="select-perpage-label"
                                onChange={this.handleChangeFilter}
                                value={this.state.filter_perpage}
                                sx={{width:"100px"}}
                            >
                                {this.state.perpage.map((item, idx)=>{
                                    return <MenuItem value={idx} key={idx}>{item}</MenuItem>
                                })}
                            </Select>
                        </FormControl>
                        {!this.state.is_loading?
                        <Button variant="contained" onClick={this.handleFilterSubmit}>Filter</Button>:
                        <Typography className="btn-loading-filter">HARAP SABAR...</Typography>}
                    </Grid>
                </Grid>
                <Paper>
                    <TableContainer sx={{ maxHeight: 700 }}>
                        <Table stickyHeader aria-label="sticky table">
                        <TableHead>
                            <TableRow>
                                <TableCell>No.</TableCell>
                                <TableCell align="center">ID Order</TableCell>
                                {/* <TableCell align="center">ID Booth</TableCell>
                                <TableCell align="center">ID Frame</TableCell>
                                <TableCell align="center">ID Photo</TableCell> */}
                                <TableCell align="center">Total</TableCell>
                                <TableCell align="center">Description</TableCell>
                                <TableCell align="center" sx={{width:"115px"}}>Date</TableCell>                                
                                <TableCell align="center">Status</TableCell>
                                <TableCell align="center">Action</TableCell>                                
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {tableBody}                            
                        </TableBody>
                        </Table>
                    </TableContainer>
                    {/* <TablePagination
                        rowsPerPageOptions={[10, 25, 100]}
                        component="div"
                        count={10}
                        rowsPerPage={15}
                        page={10}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    /> */}
                </Paper>
            </Container>            
        )
    }
}

function mapDispatchToProps(dispatch) {
    return {
        getSales: data => dispatch(getSales(data)),
        getBrandAll: data => dispatch(getBrandAll(data)),
        getBoothAll: data => dispatch(getBoothAll(data)),
        getBoothSearch: data => dispatch(getBoothSearch(data)),
        getSalesSearch: data => dispatch(getSalesSearch(data))
    };
}

const mapStateToProps = state => ({
    ...state
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SalesAll));