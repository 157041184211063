import React, { Component } from 'react';
import { connect } from "react-redux";
import {
    Link,
    withRouter
} from "react-router-dom";

import { toggleMenuOFF } from "../redux/actions/frontend";

import Box from '@mui/material/Box';

class SideMenu extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isAdmin:false,
            err:null
        };

        this.handleCloseMenu = this.handleCloseMenu.bind(this);
    }

    handleCloseMenu(e){
        // e.preventDefault();
        this.props.toggleMenuOFF();
    }

    componentDidMount(){
        console.log(this.props.userData.permission);
        if(this.props.userData.roles === "admin"){
            this.setState({isAdmin:true});
        }
    }

    render() {
        const url = this.props.match.url;
        
        return (
            <Box className='main-side-menu'>
                <ul>
                    <li><Link to="/home" onClick={this.handleCloseMenu}>Home</Link></li>
                    {this.state.isAdmin && (
                        <>
                            <li><Link to={`${url}/brand`} onClick={this.handleCloseMenu}>Brands</Link></li>
                            <li><Link to={`${url}/booth`} onClick={this.handleCloseMenu}>Booths</Link></li>
                            <li><Link to={`${url}/frame`} onClick={this.handleCloseMenu}>Frames</Link></li>
                            <li><Link to={`${url}/coupon`} onClick={this.handleCloseMenu}>Vouchers</Link></li>
                        </>
                    )}
                    <li><Link to={`${url}/sales`} onClick={this.handleCloseMenu}>Sales</Link></li>
                </ul>
            </Box>    
        )
    }
}

function mapDispatchToProps(dispatch) {
    return {
        toggleMenuOFF: userData => dispatch(toggleMenuOFF(userData))
        //logout: userData => dispatch(logout(userData))
    };
}

const mapStateToProps = state => ({
    ...state
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SideMenu));