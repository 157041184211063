import React, { Component } from 'react';
import { connect } from "react-redux";
import {
    withRouter
} from "react-router-dom";

import { getBrand, updateBrand, deleteBrand } from "../redux/actions/brands";

import {
    Box,
    Paper,
    Switch,
    Button,
    Container,
    Typography,
    FormControlLabel,
    FormGroup,
    TextField
} from '@mui/material';

class BrandEdit extends Component {
    constructor(props) {
        super(props);
        this.state = {
            brands:null,
            data:{
                id:"",
                name:"",
                description:"",
                is_active:true
            },
            err:null
        };

        this.handleDelete = this.handleDelete.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleDelete(e){
        e.preventDefault()
        const answer = window.confirm("Are you sure to delete?");
        if (answer) {
            var ctx = this;
            this.props.deleteBrand({id:this.state.data.id}).then((e)=>{
                if(e.code === 200){
                    ctx.props.history.replace("/dashboard/brand");
                }else{
                    alert("Something Error");
                }
            }).catch((err)=>{
                console.log(err);
            })
        }
    }

    handleChange(e){
        let tmpVal = e.target.value;
        if(e.target.name === "is_active"){
            tmpVal = JSON.parse(e.target.checked);
        }        
        this.setState({
            data: {
                ...this.state.data,
                [e.target.name]: tmpVal
            }
        });
    }

    handleSubmit(e){
        e.preventDefault();
        const answer = window.confirm("Are you sure?");
        if (answer) {
            var ctx = this;
            this.props.updateBrand({
                id:ctx.state.data.id,
                name:ctx.state.data.name,
                description:ctx.state.data.description,
                is_active:ctx.state.data.is_active
            }).then((e)=>{
                ctx.props.history.replace("/dashboard/brand");
            }).catch((err)=>{
                console.log(err);
                alert(err.message);
            });
        }else{
            console.log("dismiss!");
        }
    }

    componentDidMount(){
        var ctx = this;
        this.props.getBrand({id:this.props.match.params.id}).then((e)=>{
            if(e.code === 200){
                ctx.setState({data:e.data});
            }else{
                alert("Something Error");
            }
        }).catch((err)=>{
            console.log(err);
        });
    }

    render() {        
        return (
            <Container className='main-panel brand'>                
                <Box mt={3}>
                    <Paper component="form" className='panel-container-box' onSubmit={this.handleSubmit}>
                    <Typography variant="h5" mt={1} mb={2}>Edit Brand</Typography>
                    <FormGroup>                        
                        <TextField
                            size="small"
                            id="input-brandname"
                            name="name"
                            label="Brand Name"
                            helperText="Nama Brand"
                            value={this.state.data.name}
                            onChange={this.handleChange}
                            required
                            sx={{marginBottom:"20px"}}
                        />

                        <TextField
                            size="small"
                            id="input-branddescription"
                            name="description"
                            label="Brand Description"
                            helperText="Deskripsi Brand"
                            value={this.state.data.description!==null?this.state.data.description:""}
                            onChange={this.handleChange}
                            multiline
                            rows={3}
                            sx={{marginBottom:"20px"}}
                        />
                        
                        <FormControlLabel 
                            control={<Switch checked={this.state.data.is_active}/>} 
                            label="Active"
                            name="is_active"
                            value={this.state.data.is_active}
                            onChange={this.handleChange}
                            sx={{marginBottom:"20px", marginLeft:"5px"}}
                        />

                        <Button type="submit" variant="contained" sx={{marginBottom:"10px"}}>Update</Button>
                        <Button onClick={this.handleDelete} variant="contained" color="error">Delete</Button>
                    </FormGroup>
                    </Paper>
                </Box>
            </Container>            
        )
    }
}

function mapDispatchToProps(dispatch) {
    return {
        getBrand: data => dispatch(getBrand(data)),
        updateBrand: data => dispatch(updateBrand(data)),
        deleteBrand: data => dispatch(deleteBrand(data))
    };
}

const mapStateToProps = state => ({
    ...state
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(BrandEdit));