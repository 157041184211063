import React, { Component } from 'react';
import { connect } from "react-redux";
import {
    withRouter
} from "react-router-dom";

import { getBoothAll, getBoothSearch } from "../redux/actions/booths";
import { getBrandAll } from "../redux/actions/brands";

import {
    Select,
    MenuItem,
    TextField,
    InputLabel,
    FormControl
} from '@mui/material';

import Chip from '@mui/material/Chip';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';

import Table from '@mui/material/Table';
import TableRow from '@mui/material/TableRow';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableContainer from '@mui/material/TableContainer';

class BoothAll extends Component {
    constructor(props) {
        super(props);
        this.state = {
            filter_idbrand:0,
            filter_name:"",
            filter_boothkey:"",
            filter_perpage:0,
            perpage:[ 30, 50, 100, 250, 500, 1000],
            brand:[],
            data:null,
            err:null,
            is_loading:false
        };

        this.handleFilterSubmit = this.handleFilterSubmit.bind(this);
        this.handleChangeFilter = this.handleChangeFilter.bind(this);
    }

    handleChangeFilter(e, f){
        this.setState({[e.target.name]:e.target.value})
    }

    handleFilterSubmit(e){
        this.setState({is_loading:true});
        e.preventDefault();

        let params = {};
        if(this.state.filter_idbrand !== 0){
            params.idbrand = this.state.filter_idbrand;
        }
        if(this.state.filter_name !== ""){
            params.name = this.state.filter_name;
        }
        if(this.state.filter_boothkey !== ""){
            params.boothkey = this.state.filter_boothkey;
        }

        params.perpage = this.state.perpage[this.state.filter_perpage];

        this.props.getBoothSearch(params).then((e)=>{
            if(e.code === 200){
                this.setState({data:e.data, is_loading:false});
            }else{
                this.setState({data:[], is_loading:false});
            }
        })
    }

    componentDidMount(){
        var ctx = this;
        this.props.getBrandAll().then((e)=>{
            if(e.code === 200){
                this.setState({brand:e.data});                
            }
        });

        this.props.getBoothAll({perpage:30}).then((e)=>{
            if(e.code === 200){
                ctx.setState({data:e.data});
            }else{
                ctx.setState({err:e.message});
            }
        }).catch((e)=>{
            alert("Something Error");
        });
    }

    render() {
        const url = this.props.match.url;
        var tableBody;

        if(this.state.data !== null){
            tableBody = this.state.data.map((item, idx)=>{
                let statusBooth = "Connecting...";
                if(this.props.socket.length !== 0){
                    let idxBooth = this.props.socket.findIndex(p => p.boothkey === item.boothkey);                    
                    if(idxBooth > 0){
                        statusBooth = this.props.socket[idxBooth].state;
                    }else{
                        statusBooth = "Offline";
                    }
                }

                return (
                <TableRow hover role="checkbox" tabIndex={-1} key={idx}>
                    <TableCell sx={{ width:"1%", whiteSpace: "nowrap" }}>
                        {idx += 1}
                    </TableCell>
                    <TableCell align="left">
                        {item.brand !== null?<Chip sx={{fontSize:"11px"}} label={item.brand?.name.toUpperCase()} className={"brand-color-"+item.brand?.id} size="small" />:
                        <Typography sx={{fontSize:"11px", textAlign:"center"}}>Need a Brand!</Typography>}
                    </TableCell>
                    <TableCell align="left">
                        {item.boothkey}
                    </TableCell>                    
                    <TableCell align="center" sx={{ width:"1%", whiteSpace: "nowrap" }}>
                        IDR{item.price.toLocaleString("id")}
                    </TableCell>
                    <TableCell align="left">
                        {item.name}
                    </TableCell>
                    <TableCell>
                        {item.description}
                    </TableCell>
                    <TableCell align="center">
                        <Chip sx={{fontSize:"11px"}} variant="contained" size="small" color={statusBooth==="Offline"?"error":"success"} label={statusBooth.toUpperCase()}/>
                    </TableCell>
                    <TableCell align="center" sx={{ whiteSpace: "nowrap" }}>
                        {/* width:"140px",  */}
                        <Grid container gap={1} alignItems="center">    
                            {/* <Button size="small" variant="contained" color="error" onClick={(e)=>{
                                this.props.history.push(url+"/edit/"+item.boothkey);
                            }}>Sync</Button> */}
                            <Button size="small" variant="contained" color="warning" onClick={(e)=>{
                                this.props.history.push(url+"/edit/"+item.boothkey);
                            }}>Edit</Button>
                        </Grid>
                    </TableCell>
                </TableRow>
                )
            })
        }

        return (
            <Container className='main-panel booth'>
                <Grid container className="header-panel" alignItems="center" mb={1}>
                    <Grid item md={6} xs={6} sx={{justifyContent:"flex-start"}}>
                        <Typography variant="h6">{!this.props.title?"":this.props.title}</Typography>
                    </Grid>
                    <Grid item md={6} xs={6} sx={{textAlign:"right"}}>
                        <Button variant="contained" color="primary" onClick={(e)=>{
                            this.props.history.push(url+"/add");
                        }}>Add Booth</Button>
                    </Grid>
                </Grid>
                <Grid container className="header-panel" alignItems="center" sx={{ paddingTop: "10px",paddingBottom: "10px", marginBottom:"10px" }}>
                    <Grid item md={12} xs={12} sx={{display:"flex", gap:"5px", justifyContent:"flex-start", flexWrap: {xs:"wrap", md:"nowrap"}}}>
                        <FormControl sx={{ minWidth: 120 }}>
                            <InputLabel id="select-brand-label">Brand</InputLabel>
                            <Select
                                size="small"
                                label="Brand"
                                name="filter_idbrand"
                                id="select-brand"
                                labelId="select-brand-label"
                                onChange={this.handleChangeFilter}
                                value={this.state.filter_idbrand}
                            >
                                <MenuItem value={0} key={-1}>All</MenuItem>
                                {this.state.brand.map((item)=>{
                                    return <MenuItem value={item.id} key={item.id}>{item.name}</MenuItem>
                                })}
                            </Select>
                        </FormControl>
                        <TextField 
                            size="small"
                            id="input-boothkey"
                            name="filter_boothkey"
                            label="Boothkey"
                            placeholder="pp-bdo-umbira"
                            onChange={this.handleChangeFilter}
                            value={this.state.filter_boothkey}
                        >
                        </TextField>
                        <TextField 
                            size="small"
                            id="input-boothname"
                            name="filter_name"
                            label="Booth Name"
                            placeholder="Umbira"
                            onChange={this.handleChangeFilter}
                            value={this.state.filter_name}
                        >
                        </TextField>
                        <FormControl>
                            <InputLabel id="select-perpage-label">Per Page</InputLabel>
                            <Select
                                size="small"
                                label="Perpage"
                                name="filter_perpage"
                                id="select-perpage"
                                labelId="select-perpage-label"
                                onChange={this.handleChangeFilter}
                                value={this.state.filter_perpage}
                                sx={{width:"100px"}}
                            >
                                {this.state.perpage.map((item, idx)=>{
                                    return <MenuItem value={idx} key={idx}>{item}</MenuItem>
                                })}
                            </Select>
                        </FormControl>
                        {!this.state.is_loading?
                        <Button variant="contained" onClick={this.handleFilterSubmit}>Filter</Button>:
                        <Typography className="btn-loading-filter">HARAP SABAR...</Typography>}
                    </Grid>
                </Grid>
                <Paper>
                    <TableContainer sx={{ maxHeight: 700 }}>
                        <Table stickyHeader aria-label="sticky table">
                        <TableHead>
                            <TableRow>
                                <TableCell>No.</TableCell>
                                <TableCell align="left">Brand</TableCell>
                                <TableCell align="left">Boothkey</TableCell>
                                <TableCell align="center">Price</TableCell>
                                <TableCell align="left">Name</TableCell>
                                <TableCell align="left">Description</TableCell>
                                <TableCell align="center">Status</TableCell>
                                <TableCell align="center">Action</TableCell>                                
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {tableBody}                            
                        </TableBody>
                        </Table>
                    </TableContainer>                    
                </Paper>
            </Container>            
        )
    }
}

function mapDispatchToProps(dispatch) {
    return {
        getBrandAll: data => dispatch(getBrandAll(data)),
        getBoothAll: data => dispatch(getBoothAll(data)),
        getBoothSearch: data => dispatch(getBoothSearch(data))        
    };
}

const mapStateToProps = state => ({
    ...state
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(BoothAll));