import React, { Component } from 'react';
import { connect } from "react-redux";
import {
    // Link,
    withRouter
} from "react-router-dom";

import { getBooth, syncBooth, updateBooth, deleteBooth } from "../redux/actions/booths";
import { getBrandAll } from "../redux/actions/brands";
import { getFrameAll, getFrameSearch } from "../redux/actions/frames";

import Box from '@mui/material/Box';
// import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import Switch from '@mui/material/Switch';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

import Checkbox from '@mui/material/Checkbox';
import Autocomplete from '@mui/material/Autocomplete';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

// import Dialog from '@mui/material/Dialog';
// import DialogActions from '@mui/material/DialogActions';
// import DialogContent from '@mui/material/DialogContent';
// import DialogContentText from '@mui/material/DialogContentText';
// import DialogTitle from '@mui/material/DialogTitle';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import InputLabel from '@mui/material/InputLabel';
import { FormControl, FormControlLabel, FormGroup, TextField } from '@mui/material';

class BoothEdit extends Component {
    constructor(props) {
        super(props);
        this.state = {
            brands:null,
            frames:null,
            selectedFrame:[],
            selectedID:[],
            data:{
                idbrand:"",
                id:"",
                name:"",
                price:"",
                boothkey:"",
                description:"",
                is_active:true,
            },
            err:null
        };

        this.handleSync = this.handleSync.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleSync(e){
        e.preventDefault()
        const answer = window.confirm("Are you sure to Sync?");
        if (answer) {
            this.props.syncBooth({boothkey:this.state.data.boothkey}).then((e)=>{
                console.log("Sync Success");
                console.log(e)
            }).catch((err)=>{
                console.log(err);
            })
        }
    }

    handleDelete(e){
        e.preventDefault()
        const answer = window.confirm("Are you sure to delete?");
        if (answer) {
            let ctx = this;
            this.props.deleteBooth({id:this.state.data.id}).then((e)=>{
                if(e.code === 200){
                    ctx.props.history.replace("/dashboard/booth");
                }else{
                    alert("Something Error");
                }
            }).catch((err)=>{
                console.log(err);
            })
        }
    }

    handleChange(e){
        let tmpVal = e.target.value;
        let ctx = this;
        
        if(e.target.name === "idbrand"){
            ctx.props.getFrameSearch({idbrand:tmpVal}).then((ef)=>{
                if(ef.code === 200){
                    var dataframe = ef.data;
                    ctx.setState({ frames:null, selectedID:[], selectedFrame:[] }, function(){
                        ctx.setState({ frames:dataframe });
                    });
                }else{
                    ctx.setState({ frames:null });
                }
            }).catch((err)=>{
                console.log(err);
            });
        }

        if(e.target.name === "is_active"){
            tmpVal = JSON.parse(e.target.checked);
        }        
        this.setState({
            data: {
                ...this.state.data,
                [e.target.name]: tmpVal
            }
        });
    }

    handleSubmit(e){
        e.preventDefault();        
        const answer = window.confirm("Are you sure to update?");
        if (answer) {
            var ctx = this;
            this.props.updateBooth({
                id:ctx.state.data.id,
                boothkey:ctx.state.data.boothkey,
                name:ctx.state.data.name,
                price:ctx.state.data.price,
                description:ctx.state.data.description,
                is_active:ctx.state.data.is_active,
                idbrand:ctx.state.data.idbrand,
                selectedFrame:ctx.state.selectedFrame
            }).then((e)=>{
                if(e.code === 200){
                    alert("Update Booth Success!!!");
                    // ctx.props.history.replace("/dashboard/booth");
                }else{
                    alert("Something Error");
                }
            }).catch((err)=>{
                console.log(err);
                alert(err.message);
            });
        }else{
            console.log("dismiss!");
        }
    }

    componentDidMount(){
        var ctx = this;
        ctx.props.getBrandAll().then((e)=>{
            if(e.code === 200){
                ctx.setState({brands:e.data});
            }else{
                console.log("Something Error");
            }
        }).catch((err)=>{
            console.log(err);
        });

        ctx.props.getBooth({boothkey:ctx.props.match.params.id}).then((e)=>{
            if(e.code === 200){
                let data = e.data;                
                data.idbrand = e.data.brand?.id;
                ctx.setState({data:data, selectedFrame:e.data.frames}, function(){
                    ctx.props.getFrameSearch({idbrand:data.idbrand}).then((ef)=>{
                        if(ef.code === 200){
                            var dataframe = ef.data;
                            var selectedIDtmp = [];
                            ctx.state.selectedFrame.map((item)=>(
                                selectedIDtmp.push(dataframe.findIndex(x => x.id === item.id))
                            ))
                            ctx.setState({ frames:dataframe, selectedID:selectedIDtmp });
                        }else{
                            ctx.setState({ frames:null });
                        }
                    }).catch((err)=>{
                        console.log(err);
                    });
                });
            }else{
                alert("Something Error Booth");
            }
        }).catch((err)=>{
            console.log(err);
        });
    }

    render() {
        var statusBooth = "";
        if(this.props.socket.length !== 0){
            let idxBooth = this.props.socket.findIndex(p => p.boothkey === this.state.data.boothkey);
            if(idxBooth > 0){
                statusBooth = this.props.socket[idxBooth].state;
            }else{
                statusBooth = "Offline";
            }
        }

        return (
            <Container className='main-panel booth'>                
                <Box mt={3}>
                    <Paper component="form" className='panel-container-box' onSubmit={this.handleSubmit}>
                    <Typography variant="h5" mt={1} mb={2}>Edit Booth</Typography>
                    <FormGroup>
                        {this.state.brands!==null?
                        <FormControl sx={{ minWidth: 120 }}>
                            <InputLabel id="select-brand-label">Brand</InputLabel>
                            <Select
                                size="small"
                                labelId="select-brand-label"
                                id="select-brand"
                                name="idbrand"
                                value={this.state.data.idbrand || ""}
                                label="Brand"
                                onChange={this.handleChange}
                                sx={{marginBottom:"20px"}}
                            >
                                {this.state.brands.map((item)=>{
                                    return <MenuItem value={item.id} key={item.id}>{item.name}</MenuItem>
                                })}
                            </Select>
                        </FormControl>
                        :<Typography>Loading...</Typography>}
                        <TextField
                            size="small"
                            id="input-boothname"
                            name="name"
                            label="Booth Name"
                            helperText="Nama Booth"
                            value={this.state.data.name}
                            onChange={this.handleChange}
                            required
                            sx={{marginBottom:"20px"}}
                        />

                        <TextField
                            size="small"
                            id="input-boothprice"
                            name="price"
                            label="Booth Price"
                            helperText="Harga Booth"
                            value={this.state.data.price}
                            onChange={this.handleChange}
                            required
                            sx={{marginBottom:"20px"}}
                        />

                        <TextField
                            size="small"
                            id="input-boothkey"
                            name="boothkey"
                            label="Booth Key"
                            helperText="Tanpa spasi, huruf kecil"
                            value={this.state.data.boothkey}
                            onChange={this.handleChange}
                            required
                            sx={{marginBottom:"20px"}}
                        />

                        <TextField
                            size="small"
                            id="input-boothdescription"
                            name="description"
                            label="Booth Description"
                            helperText="Deskripsi Booth"
                            value={this.state.data.description!==null?this.state.data.description:""}
                            onChange={this.handleChange}
                            multiline
                            rows={3}
                            sx={{marginBottom:"20px"}}
                        />

                        {this.state.frames !== null?
                        <Autocomplete
                            multiple
                            size="small"
                            id="checkboxes-tags-demo"
                            onChange={(event, newValue) => {
                                this.setState({selectedFrame:newValue});                                
                            }}
                            autoHighlight                        
                            disableCloseOnSelect
                            defaultValue={this.state.selectedID.map((item)=>(this.state.frames[item]))}
                            options={this.state.frames}
                            // value={this.state.selectedFrame}
                            filterSelectedOptions
                            sx={{marginBottom:"20px"}}
                            getOptionLabel={(option) => option?.name}
                            renderOption={(props, option, { selected }) => {
                                return(
                                <li {...props} style={{ padding:"0px" }} data-idframe={option.id}>
                                <Checkbox
                                    icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                                    checkedIcon={<CheckBoxIcon fontSize="small" />}
                                    style={{ marginRight: 8 }}
                                    checked={ selected }
                                />
                                <img alt="frame-cover" width="50px" height="auto" src={option.fullurl_image} style={{marginRight:"10px"}}/>
                                {option.name}
                                </li>
                                )
                            }}
                            renderInput={(params) => (
                                <TextField {...params} label="Frames" placeholder="Frames" helperText="Pilih frame"/>
                            )}
                        />
                        :"Loading"}
                        
                        <FormControlLabel 
                            control={<Switch checked={this.state.data.is_active} />} 
                            label="Active"
                            name="is_active"
                            value={this.state.data?.is_active}
                            onChange={this.handleChange}
                            sx={{marginBottom:"20px", marginLeft:"5px"}}
                        />

                        <Button type="submit" variant="contained" sx={{marginBottom:"10px"}}>Update</Button>
                        <Button onClick={this.handleDelete} variant="contained" color="error">Delete</Button>
                    </FormGroup>
                    </Paper>

                    <Paper className='sync-booth-container' sx={{marginTop:"10px"}}>
                        <Typography variant="h5" mt={1} mb={2}>Sync Frame</Typography>
                        <Typography sx={{ display:"block" }} mb={2}> Booth State : {statusBooth!==""?<Typography sx={{textTransform:"capitalize"}}>{statusBooth}</Typography>:"Not Active"} </Typography>
                        <Button onClick={this.handleSync} variant="contained" color="success">Sync Frame</Button>                        
                    </Paper>
                </Box>
            </Container>            
        )
    }
}

function mapDispatchToProps(dispatch) {
    return {
        getBooth: data => dispatch(getBooth(data)),
        syncBooth: data => dispatch(syncBooth(data)),
        getFrameAll: data => dispatch(getFrameAll(data)),
        getFrameSearch: data => dispatch(getFrameSearch(data)),           
        getBrandAll: data => dispatch(getBrandAll(data)),
        updateBooth: data => dispatch(updateBooth(data)),
        deleteBooth: data => dispatch(deleteBooth(data))
    };
}

const mapStateToProps = state => ({
    ...state
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(BoothEdit));