export const convertToBase64 = async (uri) => {    
    return new Promise((resolve, reject) => {
        var xhr = new XMLHttpRequest();
        xhr.onload = function() {
            var reader = new FileReader();
            reader.onloadend = function() {
                resolve(reader.result);
            }
            reader.readAsDataURL(xhr.response);
        };
        let rand = Math.random() * 1000;
        xhr.open('GET', uri+"?idcache="+rand);
        xhr.responseType = 'blob';
        xhr.send();
    })
}